#content {
    padding: 25px 0;


    .inventory-table {
        border: 1px solid #dee2e6;

        tr > td:last-child {
            font-weight: bold;
            text-align: right;
        }
    }

    .btn-booking {
        color: #212529;
        background-color: #fecc00;
        border-color: #fecc00;
        font-size: 2rem;
        text-transform: uppercase;
        width: 100%;
        padding: .5rem .75rem;
        text-align: center;

        &:hover {
            opacity: 0.8;
        }

        @media (min-width: 768px) {
            font-size: 3rem;
        }

        img {
            margin-right: 15px;
        }
    }
}