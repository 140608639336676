/**
Colors
 */
/**
Fonts
 */
@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,500,700");
html, body {
  font-family: 'Ubuntu', sans-serif; }

body {
  background: #fff; }

img {
  max-width: 100%; }

.btn-link {
  color: #fff; }
  .btn-link:hover, .btn-link:focus {
    color: #fff;
    text-decoration: none;
    opacity: 0.8; }

.btn-default {
  color: #212529;
  background-color: #fecc00;
  border-color: #fecc00;
  font-size: 1rem;
  text-transform: uppercase;
  padding: .5rem 2rem;
  text-align: center;
  -webkit-box-shadow: unset !important;
  -moz-box-shadow: unset !important;
  box-shadow: unset !important; }
  .btn-default:hover {
    opacity: 0.8; }
  @media (min-width: 768px) {
    .btn-default {
      font-size: 1.5rem; } }

.btn-close {
  color: #fff;
  background-color: #54b4ff;
  border-color: #54b4ff;
  font-size: 1.5rem;
  text-transform: uppercase;
  width: 100%;
  padding: .5rem .75rem;
  text-align: center;
  -webkit-box-shadow: unset !important;
  -moz-box-shadow: unset !important;
  box-shadow: unset !important; }
  .btn-close:hover {
    opacity: 0.8;
    color: #fff; }
  @media (min-width: 768px) {
    .btn-close {
      font-size: 2.5rem; } }

button {
  cursor: pointer; }

.menu-wrapper > span {
  text-transform: uppercase;
  color: #adadad; }

#hamburger-icon {
  width: 45px;
  height: 30px;
  position: relative;
  display: inline-block; }
  #hamburger-icon .line {
    display: block;
    background: #54b4ff;
    width: 45px;
    height: 7px;
    position: absolute;
    left: 0;
    border-radius: 3.5px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s; }
    #hamburger-icon .line.line-1 {
      top: 0; }
    #hamburger-icon .line.line-2 {
      top: 11.5px; }
    #hamburger-icon .line.line-3 {
      bottom: 0; }
  #hamburger-icon:hover .line-1, #hamburger-icon:focus .line-1 {
    transform: translateY(-3.5px);
    -webkit-transform: translateY(-3.5px);
    -moz-transform: translateY(-3.5px); }
  #hamburger-icon:hover .line-3, #hamburger-icon:focus .line-3 {
    transform: translateY(3.5px);
    -webkit-transform: translateY(3.5px);
    -moz-transform: translateY(3.5px); }

html.mm-wrapper_opened #hamburger-icon {
  height: 37px; }
  html.mm-wrapper_opened #hamburger-icon .line-1 {
    transform: translateY(15px) translateX(0) rotate(45deg);
    -webkit-transform: translateY(15px) translateX(0) rotate(45deg);
    -moz-transform: translateY(15px) translateX(0) rotate(45deg); }
  html.mm-wrapper_opened #hamburger-icon .line-2 {
    opacity: 0; }
  html.mm-wrapper_opened #hamburger-icon .line-3 {
    transform: translateY(-15px) translateX(0) rotate(-45deg);
    -webkit-transform: translateY(-15px) translateX(0) rotate(-45deg);
    -moz-transform: translateY(-15px) translateX(0) rotate(-45deg); }

@media (min-width: 768px) {
  .menu-wrapper > span {
    text-transform: uppercase;
    color: #adadad; }
  #hamburger-icon {
    width: 75px;
    height: 40px;
    position: relative;
    display: inline-block; }
    #hamburger-icon .line {
      display: block;
      background: #54b4ff;
      width: 75px;
      height: 10px;
      position: absolute;
      left: 0;
      border-radius: 5px;
      transition: all 0.4s;
      -webkit-transition: all 0.4s;
      -moz-transition: all 0.4s; }
      #hamburger-icon .line.line-1 {
        top: 0; }
      #hamburger-icon .line.line-2 {
        top: 15px; }
      #hamburger-icon .line.line-3 {
        bottom: 0; }
    #hamburger-icon:hover .line-1, #hamburger-icon:focus .line-1 {
      transform: translateY(-5px);
      -webkit-transform: translateY(-5px);
      -moz-transform: translateY(-5px); }
    #hamburger-icon:hover .line-3, #hamburger-icon:focus .line-3 {
      transform: translateY(5px);
      -webkit-transform: translateY(5px);
      -moz-transform: translateY(5px); }
  html.mm-wrapper_opened #hamburger-icon {
    height: 50px; }
    html.mm-wrapper_opened #hamburger-icon .line-1 {
      transform: translateY(20px) translateX(0) rotate(45deg);
      -webkit-transform: translateY(20px) translateX(0) rotate(45deg);
      -moz-transform: translateY(20px) translateX(0) rotate(45deg); }
    html.mm-wrapper_opened #hamburger-icon .line-2 {
      opacity: 0; }
    html.mm-wrapper_opened #hamburger-icon .line-3 {
      transform: translateY(-20px) translateX(0) rotate(-45deg);
      -webkit-transform: translateY(-20px) translateX(0) rotate(-45deg);
      -moz-transform: translateY(-20px) translateX(0) rotate(-45deg); } }

header {
  background-color: #fff;
  border-bottom: 2px solid #fecc00; }
  header a {
    color: #54b4ff;
    transition: 0.1s; }
    header a:hover {
      color: #54b4ff;
      font-size: 1.1em; }
  header img.logo {
    margin: 15px 0; }
  header #navigation {
    display: none;
    list-style-type: none;
    margin: 0;
    padding: 0; }
    header #navigation li {
      display: inline-block;
      margin-left: 40px; }
      header #navigation li:first-child {
        margin-left: 0; }
      header #navigation li a {
        color: #54b4ff;
        text-transform: uppercase;
        text-decoration: none;
        display: inline-block;
        position: relative;
        padding-bottom: 3px;
        transition: 0.5s; }
        header #navigation li a::before, header #navigation li a::after {
          width: 100%;
          height: 100%;
          z-index: 3;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          box-sizing: border-box;
          -webkit-transform: scale(0);
          transition: 0.5s; }
        header #navigation li a::before {
          border-bottom: 3px solid #54b4ff;
          border-left: 0;
          -webkit-transform-origin: 0% 100%; }
        header #navigation li a::after {
          border-top: 0;
          border-right: 0;
          -webkit-transform-origin: 50% 50%; }
        header #navigation li a:hover::after, header #navigation li a:hover::before {
          -webkit-transform: scale(1); }
    @media (min-width: 992px) {
      header #navigation {
        display: inline-block; } }

#masterslider .ms-caption {
  background-color: #54b4ff;
  color: #fff;
  padding: 10px 20px;
  font-size: 0.875rem; }
  #masterslider .ms-caption * {
    color: #fff; }
  #masterslider .ms-caption .slide-header {
    font-size: 1rem;
    font-weight: bold; }
  @media (min-width: 576px) {
    #masterslider .ms-caption {
      padding: 20px 30px;
      font-size: 1.5rem; }
      #masterslider .ms-caption .slide-header {
        font-size: 2rem; } }
  @media (min-width: 768px) {
    #masterslider .ms-caption {
      padding: 40px 50px;
      font-size: 2rem; }
      #masterslider .ms-caption .slide-header {
        font-size: 3rem; } }

#weatherslider-big .ms-view,
#weatherslider-small .ms-view {
  background-color: #fff; }

#weatherslider-big .temperature,
#weatherslider-big .water,
#weatherslider-big .rain,
#weatherslider-small .temperature,
#weatherslider-small .water,
#weatherslider-small .rain {
  border: 1px solid #d9f2f4;
  padding: 0.75em 1.5em;
  text-align: center; }
  #weatherslider-big .temperature i,
  #weatherslider-big .water i,
  #weatherslider-big .rain i,
  #weatherslider-small .temperature i,
  #weatherslider-small .water i,
  #weatherslider-small .rain i {
    margin-bottom: 10px; }

#weatherslider-big .temperature i,
#weatherslider-small .temperature i {
  color: #fece1b; }

#weatherslider-big .water i,
#weatherslider-small .water i {
  color: #299dbe; }

#weatherslider-big .rain i,
#weatherslider-small .rain i {
  color: #00accb; }

#weatherslider-big .small-view,
#weatherslider-small .small-view {
  border: 1px solid #d9f2f4;
  padding: 0.75em 1.5em;
  text-align: center; }
  #weatherslider-big .small-view > div:first-child i.fa-sun,
  #weatherslider-small .small-view > div:first-child i.fa-sun {
    color: #fece1b; }
  #weatherslider-big .small-view > div:first-child i.fa-water,
  #weatherslider-small .small-view > div:first-child i.fa-water {
    color: #299dbe; }
  #weatherslider-big .small-view > div:first-child i.fa-cloud-showers-heavy,
  #weatherslider-small .small-view > div:first-child i.fa-cloud-showers-heavy {
    color: #00accb; }

#weatherslider-big .ms-nav-next,
#weatherslider-big .ms-nav-prev,
#weatherslider-small .ms-nav-next,
#weatherslider-small .ms-nav-prev {
  top: 11px; }

#weatherslider-big .ms-nav-next,
#weatherslider-small .ms-nav-next {
  right: -20px; }

#weatherslider-big .ms-nav-prev,
#weatherslider-small .ms-nav-prev {
  left: -20px; }

#weatherslider-big {
  display: none !important;
  visibility: hidden !important; }
  @media (min-width: 992px) {
    #weatherslider-big .temperature,
    #weatherslider-big .water,
    #weatherslider-big .rain {
      font-size: 1.3em; } }
  @media (min-width: 1200px) {
    #weatherslider-big .temperature,
    #weatherslider-big .water,
    #weatherslider-big .rain {
      font-size: 1.4em; } }

#weatherslider-small {
  display: block !important;
  visibility: visible !important; }

@media (min-width: 768px) {
  #weatherslider-big {
    display: block !important;
    visibility: visible !important; }
  #weatherslider-small {
    display: none !important;
    visibility: hidden !important; } }

#content {
  padding: 25px 0; }
  #content .inventory-table {
    border: 1px solid #dee2e6; }
    #content .inventory-table tr > td:last-child {
      font-weight: bold;
      text-align: right; }
  #content .btn-booking {
    color: #212529;
    background-color: #fecc00;
    border-color: #fecc00;
    font-size: 2rem;
    text-transform: uppercase;
    width: 100%;
    padding: .5rem .75rem;
    text-align: center; }
    #content .btn-booking:hover {
      opacity: 0.8; }
    @media (min-width: 768px) {
      #content .btn-booking {
        font-size: 3rem; } }
    #content .btn-booking img {
      margin-right: 15px; }

.accordion {
  margin-bottom: 15px; }
  .accordion .card {
    -webkit-border-radius: unset;
    -moz-border-radius: unset;
    border-radius: unset;
    border: none; }
  .accordion .card-header {
    padding: 0;
    background: #54b4ff;
    -webkit-border-radius: unset;
    -moz-border-radius: unset;
    border-radius: unset;
    border: 1px solid #d9f2f4; }
  .accordion button {
    font-size: 1.5rem;
    display: block;
    position: relative;
    text-align: left;
    width: 100%; }
    .accordion button span {
      display: inline-block;
      position: absolute;
      top: 16%;
      right: 3%;
      color: #fff;
      line-height: 1em; }
      .accordion button span i {
        font-size: 1rem; }
      .accordion button span i:first-child {
        display: inline-block; }
      .accordion button span i:last-child {
        display: none; }
    .accordion button.collapsed span i:first-child {
      display: none; }
    .accordion button.collapsed span i:last-child {
      display: inline-block; }
    @media (min-width: 576px) {
      .accordion button {
        font-size: 2rem; }
        .accordion button span {
          top: 16%; }
          .accordion button span i {
            font-size: 1.5rem; } }

body.beach {
  background: #fff5cc; }

#beachHead {
  height: 230px;
  background: #fff5cc url("/web/img/water-bg.png") repeat-x bottom;
  color: #fff;
  padding: 1.75em 1.5em; }
  #beachHead > span {
    text-transform: uppercase;
    font-size: 1rem; }
  #beachHead h1 {
    text-transform: uppercase;
    font-size: 2.25rem; }
  #beachHead #basketIcon {
    display: inline-block;
    position: relative;
    color: #fff; }
    #beachHead #basketIcon:hover {
      color: #fff; }
    #beachHead #basketIcon span.badge {
      background-color: #fecc00;
      color: #fff;
      position: absolute;
      top: -10px;
      right: -17px;
      -webkit-border-radius: 2rem;
      -moz-border-radius: 2rem;
      border-radius: 2rem;
      padding: 0.3em 0.5em; }
  @media (min-width: 576px) {
    #beachHead > span {
      font-size: 1.5rem; }
    #beachHead h1 {
      font-size: 3.5rem; }
    #beachHead #basketIcon {
      font-size: 1.5rem; } }

svg {
  max-width: 1000px;
  margin: 0 auto; }

g.umbrella.free, g.umbrella.basket {
  cursor: pointer; }

g.umbrella rect {
  cursor: pointer;
  fill: transparent;
  fill-opacity: 0; }

g.umbrella.free {
  fill: #94a828; }
  g.umbrella.free .umbrellaNumber {
    fill: #94a828; }

g.umbrella.reservedly {
  fill: #54b4ff; }
  g.umbrella.reservedly .umbrellaNumber {
    fill: #54b4ff; }

g.umbrella.basket {
  fill: #fecc00; }
  g.umbrella.basket .umbrellaNumber {
    fill: #fecc00; }
  g.umbrella.basket rect {
    stroke: #40bcc8;
    stroke-width: 2;
    stroke-miterlimit: 4;
    stroke-dasharray: none;
    stroke-opacity: 1; }

g.umbrella .umbrellaNumber {
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-stretch: normal;
  font-size: 4rem;
  line-height: 1.25;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-feature-settings: normal;
  letter-spacing: 0px;
  word-spacing: 0px;
  writing-mode: lr-tb;
  fill-opacity: 1;
  stroke: none;
  stroke-width: 1.31691885; }

#choosepplkosten {
  display: inline-block;
  margin-right: 10px; }

@media (min-width: 768px) {
  .reservationModal {
    min-width: 700px; } }

.reservationModal .big-buttons {
  padding: 10px 0; }
  .reservationModal .big-buttons > div:first-child,
  .reservationModal .big-buttons > div:last-child {
    padding-left: 0;
    padding-right: 0; }
  .reservationModal .big-buttons button {
    line-height: 1em;
    font-size: 3em;
    font-weight: bold; }
    .reservationModal .big-buttons button.minus {
      padding: 4px 25px 14px; }
    .reservationModal .big-buttons button.plus {
      padding: 4px 20px 14px; }

.reservationModal .number,
.reservationModal .amount {
  font-size: 3rem;
  font-weight: bold;
  border: 1px solid #fff;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  border-radius: 0.25rem;
  width: 100%; }
  .reservationModal .number.block,
  .reservationModal .amount.block {
    border: none;
    font-style: 2.5rem; }

.reservationModal #ppl-minus,
.reservationModal #ppl-plus {
  background-color: #54b4ff;
  border: none; }
  .reservationModal #ppl-minus button,
  .reservationModal #ppl-plus button {
    font-weight: bold;
    padding: 12px 40px 30px; }

.reservationModal .persons {
  font-size: 3rem;
  font-weight: bold; }

.reservationModal .buttons .row > div {
  padding-left: 5px;
  padding-right: 5px; }

.reservationModal .btn-basket {
  color: #212529;
  background-color: #fecc00;
  border-color: #fecc00;
  font-size: .875rem;
  text-transform: uppercase;
  width: 100%;
  padding: .5rem .75rem;
  text-align: center; }
  .reservationModal .btn-basket:hover {
    opacity: 0.8; }
  @media (min-width: 768px) {
    .reservationModal .btn-basket {
      font-size: 2rem; } }

.reservationModal .btn-buy {
  color: #fff;
  background-color: #54b4ff;
  border-color: #54b4ff;
  font-size: .875rem;
  text-transform: uppercase;
  width: 100%;
  padding: .5rem .75rem;
  text-align: center; }
  .reservationModal .btn-buy:hover {
    opacity: 0.8; }
  @media (min-width: 768px) {
    .reservationModal .btn-buy {
      font-size: 2rem; } }

#basketHead {
  height: 140px;
  background-color: #54b4ff;
  color: #fff;
  padding: 1.75em 1.5em; }
  #basketHead > span {
    text-transform: uppercase;
    font-size: 1rem; }
  #basketHead h1 {
    text-transform: uppercase;
    font-size: 2.25rem; }
  #basketHead #basketIcon {
    display: inline-block;
    position: relative;
    color: #fff; }
    #basketHead #basketIcon:hover {
      color: #fff; }
    #basketHead #basketIcon span.badge {
      background-color: #fecc00;
      color: #fff;
      position: absolute;
      top: -10px;
      right: -17px;
      -webkit-border-radius: 2rem;
      -moz-border-radius: 2rem;
      border-radius: 2rem;
      padding: 0.3em 0.5em; }
  @media (min-width: 576px) {
    #basketHead {
      height: 170px; }
      #basketHead > span {
        font-size: 1.5rem; }
      #basketHead h1 {
        font-size: 3.5rem; }
      #basketHead #basketIcon {
        font-size: 1.5rem; } }

#basketBody .accordion .card-header {
  background: #54b4ff;
  -webkit-border-radius: unset;
  -moz-border-radius: unset;
  border-radius: unset;
  border: 1px solid #54b4ff; }

#basketBody .accordion #collapseOne .card-body {
  padding: 0; }

#basketBody .accordion #collapseTwo .row > div {
  margin-bottom: 10px; }

#basketBody .accordion #collapseThree .card-body {
  padding-left: 0;
  padding-right: 0; }

#basketBody table.basket-articles thead th {
  border-bottom: 1px solid #d9f2f4;
  border-top: none !important; }

#basketBody table.basket-articles td, #basketBody table.basket-articles th {
  vertical-align: baseline;
  border-top: 1px solid #d9f2f4; }

#basketBody table.basket-articles tr td {
  font-size: 1rem;
  vertical-align: baseline; }

#basketBody table.basket-articles tr.article td {
  position: relative; }

#basketBody table.basket-articles tr.article td:first-child:after,
#basketBody table.basket-articles tr.article td:nth-child(2):after {
  content: '';
  width: 9px;
  height: 17px;
  background: #d9f2f4;
  position: absolute;
  bottom: 0;
  right: 0; }

#basketBody table.basket-articles tr.article td:nth-child(2),
#basketBody table.basket-articles tr.article td:nth-child(3) {
  font-size: 1.25rem; }
  #basketBody table.basket-articles tr.article td:nth-child(2) span,
  #basketBody table.basket-articles tr.article td:nth-child(3) span {
    font-size: 0.75rem;
    color: #757575; }

#basketBody table.basket-articles tr.article td:nth-child(3) {
  white-space: nowrap; }

#basketBody table.basket-articles a.trash {
  color: #54b4ff;
  font-size: 1.5rem; }
  #basketBody table.basket-articles a.trash:hover {
    color: #54b4ff; }

#basketBody table.basket-articles #sum td {
  font-size: 2.5rem; }

@media (min-width: 576px) {
  #basketBody table.basket-articles tr td {
    font-size: 1.5rem; }
  #basketBody table.basket-articles tr.article td:nth-child(2),
  #basketBody table.basket-articles tr.article td:nth-child(3) {
    font-size: 2rem; }
    #basketBody table.basket-articles tr.article td:nth-child(2) span,
    #basketBody table.basket-articles tr.article td:nth-child(3) span {
      font-size: 0.875rem; }
  #basketBody table.basket-articles a.trash {
    font-size: 2rem; }
  #basketBody table.basket-articles #sum td {
    font-size: 2.5rem; } }

#basketBody .btn-ready {
  color: #212529;
  background-color: #fecc00;
  border-color: #fecc00;
  font-size: 2rem;
  text-transform: uppercase;
  padding: .5rem 2rem;
  text-align: center;
  -webkit-box-shadow: unset;
  -moz-box-shadow: unset;
  box-shadow: unset;
  margin-top: 15px; }
  #basketBody .btn-ready:hover {
    opacity: 0.8; }

#accountHead {
  height: 140px;
  background-color: #40bcc8;
  color: #fff;
  padding: 1.75em 1.5em; }
  #accountHead > span {
    text-transform: uppercase;
    font-size: 1rem; }
  #accountHead h1 {
    text-transform: uppercase;
    font-size: 2.25rem; }
  #accountHead #basketIcon {
    display: inline-block;
    position: relative;
    color: #fff; }
    #accountHead #basketIcon:hover {
      color: #fff; }
    #accountHead #basketIcon span.badge {
      background-color: #fecc00;
      color: #fff;
      position: absolute;
      top: -10px;
      right: -17px;
      -webkit-border-radius: 2rem;
      -moz-border-radius: 2rem;
      border-radius: 2rem;
      padding: 0.3em 0.5em; }
  @media (min-width: 576px) {
    #accountHead {
      height: 170px; }
      #accountHead > span {
        font-size: 1.5rem; }
      #accountHead h1 {
        font-size: 3.5rem; }
      #accountHead #basketIcon {
        font-size: 1.5rem; } }

#accountBody table.table tr td {
  font-size: 1rem;
  vertical-align: baseline; }

#accountBody .table thead th {
  border-bottom: 1px solid #d9f2f4;
  border-top: none !important; }

#accountBody .table td, #accountBody .table th {
  vertical-align: baseline;
  border-top: 1px solid #d9f2f4; }

#accountBody tbody tr td {
  position: relative; }

#accountBody tbody tr td:first-child:after {
  content: '';
  width: 9px;
  height: 17px;
  background: #d9f2f4;
  position: absolute;
  bottom: 0;
  right: 0; }

@media (min-width: 576px) {
  #accountBody table.table tr td {
    font-size: 1.5rem; } }

.ticketModal {
  font-size: 2rem; }
  @media (min-width: 768px) {
    .ticketModal {
      min-width: 700px; } }
  .ticketModal .gold {
    color: #fecc00; }
  .ticketModal .location {
    font-size: 1.6875rem; }
  .ticketModal .booking-number {
    font-size: 1.125rem; }
  .ticketModal .date {
    font-size: 1.5rem; }
  .ticketModal .beachbeds {
    white-space: nowrap; }
  .ticketModal img.beachbed {
    max-width: 100%;
    width: 35px; }
  .ticketModal h1 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 2.5rem; }
  @media (min-width: 768px) {
    .ticketModal {
      font-size: 3.5rem; }
      .ticketModal .location {
        font-size: 2.125rem; }
      .ticketModal .booking-number {
        font-size: 2rem; }
      .ticketModal .date {
        font-size: 1.75rem; }
      .ticketModal h1 {
        font-size: 4rem; }
      .ticketModal img.beachbed {
        width: 70px; } }

.mm-menu {
  --mm-color-border: rgba(255, 255, 255, 0.3);
  --mm-color-button: rgba(255, 255, 255, 0.4);
  --mm-color-text: rgba(255, 255, 255, 0.85);
  --mm-color-text-dimmed: rgba(255, 255, 255, 0.4);
  --mm-color-background: #54b4ff;
  --mm-color-background-highlight: rgba(255, 255, 255, 0.08);
  --mm-color-background-emphasis: rgba(0, 0, 0, 0.1);
  --mm-shadow: 0 0 20px rgba(0, 0, 0, 0.5)
; }
