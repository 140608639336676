.accordion {
    margin-bottom: 15px;

    .card {
        -webkit-border-radius: unset;
        -moz-border-radius: unset;
        border-radius: unset;
        border: none;
    }
    
    .card-header {
        padding: 0;
        background: $mainColor;
        -webkit-border-radius: unset;
        -moz-border-radius: unset;
        border-radius: unset;
        border: 1px solid $blue;
    }

    button {
        font-size: 1.5rem;
        display: block;
        position: relative;
        text-align: left;
        width: 100%;

        span {
            display: inline-block;
            position: absolute;
            top: 16%;
            right: 3%;
            color: #fff;
            line-height: 1em;

            i {
                font-size: 1rem;
            }

            i:first-child {
                display: inline-block;
            }

            i:last-child {
                display: none;
            }
        }

        &.collapsed span {
            i:first-child {
                display: none;
            }

            i:last-child {
                display: inline-block;
            }
        }

        @media (min-width: 576px) {
            font-size: 2rem;

            span {
                top: 16%;

                i {
                    font-size: 1.5rem;
                }
            }

        }
    }
}