#masterslider {
    .ms-caption {
        background-color: $mainColor;
        color: #fff;
        padding: 10px 20px;
        font-size: 0.875rem;

        * {
            color: #fff;
        }

        .slide-header {
            font-size: 1rem;
            font-weight: bold;
        }

        @media (min-width: 576px) {
            padding: 20px 30px;
            font-size: 1.5rem;

            .slide-header {
                font-size: 2rem;
            }
        }

        @media (min-width: 768px) {
            padding: 40px 50px;
            font-size: 2rem;

            .slide-header {
                font-size: 3rem;
            }
        }
    }
}

#weatherslider-big,
#weatherslider-small {
    .ms-view {
        background-color: #fff;
    }

    .temperature,
    .water,
    .rain {
        border: 1px solid $blue;
        padding: 0.75em 1.5em;
        text-align: center;

        i {
            margin-bottom: 10px;
        }
    }

    .temperature i {
        color: #fece1b;
    }

    .water i {
        color: #299dbe;
    }

    .rain i {
        color: #00accb;
    }

    .small-view {
        border: 1px solid $blue;
        padding: 0.75em 1.5em;
        text-align: center;

        & > div:first-child {
            i.fa-sun {
                color: #fece1b;
            }

            i.fa-water {
                color: #299dbe;
            }

            i.fa-cloud-showers-heavy {
                color: #00accb;
            }
        }
    }

    //.small-view-1,
    //.small-view-2 {
    //    display: block !important;
    //    border: 1px solid $blue;
    //    padding: 0.75em 1.5em;
    //    width: 28%;
    //    visibility: visible !important;
    //
    //    @media (min-width: 768px) {
    //        display: none !important;
    //        visibility: hidden !important;
    //    }
    //}

    .ms-nav-next,
    .ms-nav-prev {
        //background: url(light-skin-1.png);
        //background-position: -89px -26px;
        top: 11px;
    }

    .ms-nav-next {
        right: -20px;
    }

    .ms-nav-prev {
        left: -20px;
    }
}

#weatherslider-big {
    display: none !important;
    visibility: hidden !important;

    .temperature,
    .water,
    .rain {
        //@media (min-width: 768px) {
        //    font-size: 1em;
        //}
        @media (min-width: 992px) {
            font-size: 1.3em;
        }
        @media (min-width: 1200px) {
            font-size: 1.4em;
        }
        //@media (min-width: 768px) {
        //    font-size: 2em;
        //}
    }
}

#weatherslider-small {
    display: block !important;
    visibility: visible !important;
}

@media (min-width: 768px) {
    #weatherslider-big {
        display: block !important;
        visibility: visible !important;
    }

    #weatherslider-small {
        display: none !important;
        visibility: hidden !important;
    }
}