$line-color: $mainColor;
$height-icon: 40px;
$width-line: 75px;
$height-line: 10px;

$transition-time: 0.4s;
$rotation: 45deg;
$translateY: ($height-icon / 2);
$translateX: 0;

.menu-wrapper > span {
    text-transform: uppercase;
    color: #adadad;
}

#hamburger-icon {
    width: $width-line;
    height: $height-icon;
    position: relative;
    display: inline-block;
    //margin: ($height-icon * 2) auto $height-icon auto;

    //@media (min-width: 992px) {
    //    display: none;
    //}

    .line {
        display: block;
        background: $line-color;
        width: $width-line;
        height: $height-line;
        position: absolute;
        left: 0;
        border-radius: ($height-line / 2);
        transition: all $transition-time;
        -webkit-transition: all $transition-time;
        -moz-transition: all $transition-time;

        &.line-1 {
            top: 0;
        }

        &.line-2 {
            top: (($height-icon / 2) - ($height-line / 2));
        }

        &.line-3 {
            bottom: 0;
        }
    }

    &:hover, &:focus {
        .line-1 {
            transform: translateY($height-line / 2 * -1);
            -webkit-transform: translateY($height-line / 2 * -1);
            -moz-transform: translateY($height-line / 2 * -1);
        }

        .line-3 {
            transform: translateY($height-line / 2);
            -webkit-transform: translateY($height-line / 2);
            -moz-transform: translateY($height-line / 2);
        }
    }
}

html.mm-wrapper_opened {
    #hamburger-icon {
        height: 50px;

        .line-1 {
            transform: translateY($translateY) translateX($translateX) rotate($rotation);
            -webkit-transform: translateY($translateY) translateX($translateX) rotate($rotation);
            -moz-transform: translateY($translateY) translateX($translateX) rotate($rotation);
        }

        .line-2 {
            opacity: 0;
        }

        .line-3 {
            transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
            -webkit-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
            -moz-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
        }
    }
}