$borderColor: #1d1d1b;

body.beach {
    background: #fff5cc;
}

#beachHead {
    height: 230px;
    background: #fff5cc url("/web/img/water-bg.png") repeat-x bottom;
    color: #fff;
    padding: 1.75em 1.5em;

    & > span {
        text-transform: uppercase;
        font-size: 1rem;
    }

    h1 {
        text-transform: uppercase;
        font-size: 2.25rem;
    }

    #basketIcon {
        display: inline-block;
        position: relative;
        color: #fff;

        &:hover {
            color: #fff;
        }

        span.badge {
            background-color: $yellow;
            color: #fff;
            position: absolute;
            top: -10px;
            right: -17px;
            -webkit-border-radius: 2rem;
            -moz-border-radius: 2rem;
            border-radius: 2rem;
            padding: 0.3em 0.5em;
        }
    }

    @media (min-width: 576px) {
        & > span {
            font-size: 1.5rem;
        }

        h1 {
            font-size: 3.5rem;
        }

        #basketIcon {
            font-size: 1.5rem;
        }
    }

    //@media (min-width: 768px) {
    //    & > span {
    //        font-size: 2rem;
    //    }
    //
    //    h1 {
    //        font-size: 4.875rem;
    //    }
    //
    //    #basketIcon {
    //        font-size: 2rem;
    //    }
    //}
}

svg {
    max-width: 1000px;
    margin: 0 auto;
}

g.umbrella {
    &.free,
    &.basket {
        cursor: pointer;
    }

    rect {
        //stroke: $borderColor;
        //stroke-width: 1;
        //stroke-miterlimit: 4;
        //stroke-dasharray: none;
        //stroke-opacity: 1
        cursor: pointer;
        fill: transparent;
        //fill: $green;
        fill-opacity: 0;
    }

    &.free {
        fill: $green;
        //fill-opacity: 0.78037379;

        .umbrellaNumber {
            fill: $green;
        }
    }

    &.reservedly {
        fill: $mainColor;
        //fill-opacity: 0.84112148;

        .umbrellaNumber {
            fill: $mainColor;
        }
    }

    &.basket {
        fill: $yellow;
        //fill-opacity: 1;

        .umbrellaNumber {
            fill: $yellow;
        }

        rect {
            stroke: $lightBlue;
            stroke-width: 2;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1
        }
    }

    .umbrellaNumber {
        font-style: normal;
        font-variant: normal;
        font-weight: bold;
        font-stretch: normal;
        font-size: 4rem;
        line-height: 1.25;
        font-variant-ligatures: normal;
        font-variant-caps: normal;
        font-variant-numeric: normal;
        font-feature-settings: normal;
        letter-spacing: 0px;
        word-spacing: 0px;
        writing-mode: lr-tb;
        //fill: #000000;
        fill-opacity: 1;
        stroke: none;
        stroke-width: 1.31691885
    }
}

#choosepplkosten {
    display: inline-block;
    margin-right: 10px;
}

.reservationModal {
    @media (min-width: 768px) {
        min-width: 700px;
    }

    .big-buttons {
        padding: 10px 0;

        & > div:first-child,
        & > div:last-child {
            padding-left: 0;
            padding-right: 0;
        }

        button {
            line-height: 1em;
            font-size: 3em;
            font-weight: bold;

            &.minus {
                padding: 4px 25px 14px;
            }

            &.plus {
                padding: 4px 20px 14px;
            }
        }
    }

    .number,
    .amount
    {
        font-size: 3rem;
        font-weight: bold;
        border: 1px solid #fff;
        -webkit-border-radius: 0.25rem;
        -moz-border-radius: 0.25rem;
        border-radius: 0.25rem;
        width: 100%;
        
        &.block {
            border: none;
            font-style: 2.5rem;
        }
    }

    #ppl-minus,
    #ppl-plus {
        background-color: $mainColor;
        border: none;

        button {
            font-weight: bold;
            padding: 12px 40px 30px;
        }
    }

    .persons {
        font-size: 3rem;
        font-weight: bold;
    }

    .buttons .row > div {
        padding-left: 5px;
        padding-right: 5px;
    }

    .btn-basket {
        color: #212529;
        background-color: $yellow;
        border-color: $yellow;
        font-size: .875rem;
        text-transform: uppercase;
        width: 100%;
        padding: .5rem .75rem;
        text-align: center;

        &:hover {
            opacity: 0.8;
        }

        @media (min-width: 768px) {
            font-size: 2rem;
        }
    }

    .btn-buy {
        color: #fff;
        background-color: $mainColor;
        border-color: $mainColor;
        font-size: .875rem;
        text-transform: uppercase;
        width: 100%;
        padding: .5rem .75rem;
        text-align: center;

        &:hover {
            opacity: 0.8;
        }

        @media (min-width: 768px) {
            font-size: 2rem;
        }
    }
}