#accountHead {
    height: 140px;
    background-color: $lightBlue;
    color: #fff;
    padding: 1.75em 1.5em;

    & > span {
        text-transform: uppercase;
        font-size: 1rem;
    }

    h1 {
        text-transform: uppercase;
        font-size: 2.25rem;
    }

    #basketIcon {
        display: inline-block;
        position: relative;
        color: #fff;

        &:hover {
            color: #fff;
        }

        span.badge {
            background-color: $yellow;
            color: #fff;
            position: absolute;
            top: -10px;
            right: -17px;
            -webkit-border-radius: 2rem;
            -moz-border-radius: 2rem;
            border-radius: 2rem;
            padding: 0.3em 0.5em;
        }
    }

    @media (min-width: 576px) {
        height: 170px;

        & > span {
            font-size: 1.5rem;
        }

        h1 {
            font-size: 3.5rem;
        }

        #basketIcon {
            font-size: 1.5rem;
        }
    }

    //@media (min-width: 768px) {
    //    & > span {
    //        font-size: 2rem;
    //    }
    //
    //    h1 {
    //        font-size: 4.875rem;
    //    }
    //
    //    #basketIcon {
    //        font-size: 2rem;
    //    }
    //}
}

#accountBody {
    table.table {
        tr td {
            font-size: 1rem;
            vertical-align: baseline;
        }
    }

    .table thead th {
        border-bottom: 1px solid $softBlue;
        border-top: none !important;
    }

    .table td, .table th {
        vertical-align: baseline;
        border-top: 1px solid $softBlue;

    }

    tbody tr {
        td {
            position: relative;
        }

        td:first-child:after {
            content: '';
            width: 9px;
            height: 17px;
            background: $softBlue;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }

    @media (min-width: 576px) {
        table.table {
            tr td {
                font-size: 1.5rem;
            }
        }
    }

    //@media (min-width: 768px) {
    //    table.table {
    //        tr td {
    //            font-size: 1.5rem;
    //        }
    //    }
    //}

    //@media (min-width: 992px) {
    //    table.table {
    //        tr td {
    //            font-size: 1.5rem;
    //        }
    //    }
    //}
}

.ticketModal {
    @media (min-width: 768px) {
        min-width: 700px;
    }

    .gold {
        color: #fecc00;
    }

    font-size: 2rem;

    .location {
        font-size: 1.6875rem;
    }

    .booking-number {
        font-size: 1.125rem;
    }

    .date {
        font-size: 1.5rem;
    }

    .beachbeds {
        white-space: nowrap;
    }

    img.beachbed {
        max-width: 100%;
        width: 35px;
    }

    h1 {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 2.5rem;
    }

    @media (min-width: 768px) {
        font-size: 3.5rem;

        .location {
            font-size: 2.125rem;
        }

        .booking-number {
            font-size: 2rem;
        }

        .date {
            font-size: 1.75rem;
        }

        h1 {
            font-size: 4rem;
        }

        img.beachbed {
            width: 70px;
        }
    }
}