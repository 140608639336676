#basketHead {
    height: 140px;
    background-color: $mainColor;
    color: #fff;
    padding: 1.75em 1.5em;

    & > span {
        text-transform: uppercase;
        font-size: 1rem;
    }

    h1 {
        text-transform: uppercase;
        font-size: 2.25rem;
    }

    #basketIcon {
        display: inline-block;
        position: relative;
        color: #fff;

        &:hover {
            color: #fff;
        }

        span.badge {
            background-color: $yellow;
            color: #fff;
            position: absolute;
            top: -10px;
            right: -17px;
            -webkit-border-radius: 2rem;
            -moz-border-radius: 2rem;
            border-radius: 2rem;
            padding: 0.3em 0.5em;
        }
    }

    @media (min-width: 576px) {
        height: 170px;

        & > span {
            font-size: 1.5rem;
        }

        h1 {
            font-size: 3.5rem;
        }

        #basketIcon {
            font-size: 1.5rem;
        }
    }

    //@media (min-width: 768px) {
    //    & > span {
    //        font-size: 2rem;
    //    }
    //
    //    h1 {
    //        font-size: 4.875rem;
    //    }
    //
    //    #basketIcon {
    //        font-size: 2rem;
    //    }
    //}
}

#basketBody {
    .accordion {
        .card-header {
            background: $mainColor;
            -webkit-border-radius: unset;
            -moz-border-radius: unset;
            border-radius: unset;
            border: 1px solid $mainColor;
        }

        #collapseOne {
            .card-body {
                padding: 0;
            }
        }

        #collapseTwo {
            .row > div {
                margin-bottom: 10px;
            }
        }

        #collapseThree {
            .card-body {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    table.basket-articles {
        thead th {
            border-bottom: 1px solid $softBlue;
            border-top: none !important;
        }

        td, th {
            vertical-align: baseline;
            border-top: 1px solid $softBlue;

        }

        tr td {
            font-size: 1rem;
            vertical-align: baseline;
        }

        tr.article {
            td {
                position: relative;
            }

            td:first-child:after,
            td:nth-child(2):after {
                content: '';
                width: 9px;
                height: 17px;
                background: $softBlue;
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }

        tr.article {
            td:nth-child(2),
            td:nth-child(3) {
                font-size: 1.25rem;

                span {
                    font-size: 0.75rem;
                    color: #757575;
                }
            }

            td:nth-child(3) {
                white-space: nowrap;
            }
        }

        a.trash {
            color: $mainColor;
            font-size: 1.5rem;

            &:hover {
                color: $mainColor;
            }
        }

        #sum {
            td {
                font-size: 2.5rem;
            }
        }
    }

    @media (min-width: 576px) {
        table.basket-articles {
            tr td {
                font-size: 1.5rem;
            }

            tr.article {
                td:nth-child(2),
                td:nth-child(3) {
                    font-size: 2rem;

                    span {
                        font-size: 0.875rem;
                    }
                }
            }

            a.trash {
                font-size: 2rem;
            }

            #sum {
                td {
                    font-size: 2.5rem;
                }
            }
        }
    }

    //@media (min-width: 768px) {
    //    table.basket-articles {
    //        tr td {
    //            font-size: 1.5rem;
    //        }
    //
    //        tr.article {
    //            td:nth-child(2),
    //            td:nth-child(3) {
    //                font-size: 2.5rem;
    //
    //                span {
    //                    font-size: 1rem;
    //                }
    //            }
    //        }
    //
    //        a.trash {
    //            font-size: 2.5rem;
    //        }
    //
    //        #sum {
    //            td {
    //                font-size: 2.625rem;
    //            }
    //        }
    //    }
    //}

    //@media (min-width: 992px) {
    //    table.basket-articles {
    //        tr td {
    //            font-size: 1.5rem;
    //        }
    //
    //        tr.article {
    //            td:nth-child(2),
    //            td:nth-child(3) {
    //                font-size: 3rem;
    //
    //                span {
    //                    font-size: 1.5rem;
    //                }
    //            }
    //        }
    //
    //        a.trash {
    //            font-size: 2.5rem;
    //        }
    //    }
    //}

    .btn-ready {
        color: #212529;
        background-color: $yellow;
        border-color: $yellow;
        font-size: 2rem;
        text-transform: uppercase;
        padding: .5rem 2rem;
        text-align: center;
        -webkit-box-shadow: unset;
        -moz-box-shadow: unset;
        box-shadow: unset;
        margin-top: 15px;

        &:hover {
            opacity: 0.8;
        }

        //@media (min-width: 768px) {
        //    font-size: 3rem;
        //}
    }
}