header {
    background-color: #fff;
    border-bottom: 2px solid $yellow;

    a {
        color: $mainColor;
        transition: 0.1s;

        &:hover {
            color: $mainColor;
            font-size: 1.1em;
        }
    }

    img.logo {
        margin: 15px 0;
    }

    #navigation {
        display: none;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            margin-left: 40px;

            &:first-child {
                margin-left: 0;
            }

            a {
                color: $mainColor;
                text-transform: uppercase;
                text-decoration: none;
                display: inline-block;
                position: relative;
                padding-bottom: 3px;

                transition: 0.5s;

                &::before, &::after {
                    width: 100%;
                    height: 100%;
                    z-index: 3;
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    box-sizing: border-box;
                    -webkit-transform: scale(0);
                    transition: 0.5s;
                }

                &::before {
                    border-bottom: 3px solid $mainColor;
                    border-left: 0;
                    -webkit-transform-origin: 0% 100%;
                }

                &::after {
                    border-top: 0;
                    border-right: 0;
                    -webkit-transform-origin: 50% 50%;
                }

                &:hover::after, &:hover::before {
                    -webkit-transform: scale(1);
                }
            }
        }

        @media (min-width: 992px) {
            display: inline-block;
        }
    }
}