/**
Colors
 */
$blue: #d9f2f4;
$mainColor: #54b4ff;
$yellow: #fecc00;
$grey: #f2f2f2;
$green: #94a828;
$lightBlue: #40bcc8;
$softBlue: #d9f2f4;

/**
Fonts
 */
@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,500,700');

html, body {
    font-family: 'Ubuntu', sans-serif;
}

body {
    background: #fff;
}

img {
    max-width: 100%;
}

.btn-link {
    color: #fff;

    &:hover,
    &:focus {
        color: #fff;
        text-decoration: none;
        opacity: 0.8;
    }
}

.btn-default {
    color: #212529;
    background-color: $yellow;
    border-color: $yellow;
    font-size: 1rem;
    text-transform: uppercase;
    padding: .5rem 2rem;
    text-align: center;
    -webkit-box-shadow: unset !important;
    -moz-box-shadow: unset !important;
    box-shadow: unset !important;

    &:hover {
        opacity: 0.8;
    }

    @media (min-width: 768px) {
        font-size: 1.5rem;
    }
}

.btn-close {
    color: #fff;
    background-color: $mainColor;
    border-color: $mainColor;
    font-size: 1.5rem;
    text-transform: uppercase;
    width: 100%;
    padding: .5rem .75rem;
    text-align: center;
    -webkit-box-shadow: unset !important;
    -moz-box-shadow: unset !important;
    box-shadow: unset !important;

    &:hover {
        opacity: 0.8;
        color: #fff;
    }

    @media (min-width: 768px) {
        font-size: 2.5rem;
    }
}

button {
    cursor: pointer;
}

@import "hamburger-menu-small";
@media (min-width: 768px) {
    @import "hamburger-menu";
}
@import "header";
@import "slider";
@import "content";
@import "accordion";
@import "beach";
@import "basket";
@import "account";
@import "mmenu";

